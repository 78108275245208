/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */


// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// Primary buttons
@mixin button-variant($background, $border, $hover-background: $background, $hover-border: $border, $active-background: $background, $active-border: $border) {
    color: color-yiq($background);
    @include gradient-bg($background);

    @include hover {
        color: color-yiq($hover-background);
        @include gradient-bg($hover-background);
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
        @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
        }
    }
}

// Outline button
@mixin button-outline-variant($color, $color-hover: #fff) {
    color: $color;
    background-color: transparent;
    background-image: none;
    border-color: $color;

    @include hover {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &:not([disabled]):not(.disabled):active,
    &:not([disabled]):not(.disabled).active,
    .show > &.dropdown-toggle {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
    }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
    line-height: $line-height;
    @include border-radius($border-radius);
}
