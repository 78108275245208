/* ------------------------------------------------------------------------------
 *
 *  # Forms mixin
 *
 *  Override and extend default forms mixin.
 *
 * ---------------------------------------------------------------------------- */
// $color-company-primary
// Form control focus state
@mixin form-control-focus() {
    &:focus {
        outline: 0;


        @if $enable-shadows {
            box-shadow: $input-box-shadow, $color-company-primary;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }


        /*
        @if $enable-shadows {
            box-shadow: $input-box-shadow, $input-focus-box-shadow;
        } @else {
            box-shadow: $input-focus-box-shadow;
        }

        */
    }
//$color-company-primary;
    &:not(.border-bottom-1):not(.border-bottom-2):not(.border-bottom-3):focus {
        border-color: $color-company-primary;
        // border-color: $input-focus-border-color;
    }
}
