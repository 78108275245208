/* ------------------------------------------------------------------------------
 *
 *  # Badge mixin
 *
 *  Override and extend default badge mixin.
 *
 * ---------------------------------------------------------------------------- */

@mixin badge-variant($bg) {
    color: color-yiq($bg);
    background-color: $bg;

    &[href] {
        @include hover-focus {
            color: color-yiq($bg);
            text-decoration: none;

            &:not(.badge-light) {
                box-shadow: $btn-dark-hover-box-shadow;
            }
        }
    }
}